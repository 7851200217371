<template>
  <v-container class="layout-container">
    <Products
      :country-code="countryCode"
      allow-country-selection
      @productSelected="handleLightProductSelected"
    />
  </v-container>
</template>

<script>
import { ProductType } from '@/lib/productTypes'

export default {
  components: {
    Products: () => import('@/components/Products')
  },
  computed: {
    countryCode () {
      const locale = this.getNavigatorLanguage
      return locale?.split('-')[1] || 'DE'
    },
    getNavigatorLanguage () {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0]
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage
      }
    }
  },
  methods: {
    handleLightProductSelected ({ productName }) {
      if (productName !== ProductType.LIGHT) return
      window.open(window.location.origin + '/auth/signup')
    }
  }

}
</script>
